.wrapper {
  width: 1000px;
  padding: 0 0 12px;
}

.headerRow {
  display: flex;
  flex-direction: row;
  height: 3em;
  font-size: 0.8em;
  color: #8c8e8e;
  border-bottom: 1px solid #8c8e8e;
}

.headerRow > div {
  line-height: 3em;
  padding: 0 4px;
  box-sizing: border-box;
}

.configRow {
  display: flex;
  flex-direction: row;
  height: 4em;
  font-size: 0.9em;
  border-bottom: 1px solid #8c8e8e;
}

.configRow:hover {
  background-color: #ebeceb;
}

.configRow > div {
  line-height: 4em;
  padding: 0 4px;
  box-sizing: border-box;
}

.nameColumn {
  flex: 0 0 auto;
  flex-basis: 25%;
}

.fieldColumn {
  flex: 0 0 auto;
  flex-basis: 50%;
}

.aggregateColumn {
  flex: 0 0 auto;
  flex-basis: 15%;
}

.aggregateColumn > div {
  margin-top: 5px;
}

.buttonColumn {
  flex: 0 0 auto;
  flex-basis: 10%;
  text-align: right;
}

.buttonColumn > div {
  margin-top: 5px !important;
}

.aggregateRow {
  display: flex;
  flex-direction: column;
  font-size: 0.8em;
  border-bottom: 1px solid #8c8e8e;
  margin: 0 3em 1em 3em;
}

.aggregateRow > div {
  display: flex;
  flex-direction: row;
  height: 4em;
  border-left: 1px solid #8c8e8e;
}

.aggregateRow > div > div {
  line-height: 4em;
  padding: 0 4px;
  box-sizing: border-box;
}

.aggregateRow .partColumn {
  flex: 0 0 auto;
  flex-basis: 40%;
}

.aggregateRow .withHeaderColumn {
  flex: 0 0 auto;
  flex-basis: 20%;
}

.aggregateRow .withHeaderColumn > div {
  margin-top: 5px;
}

.aggregateRow .detailButtonsColumn {
  flex: 0 0 auto;
  flex-basis: 20%;
  text-align: right;
}

.aggregateRow .detailButtonsColumn > div {
  margin-top: 5px !important;
}

.validationError {
  color: #c62828;
  font-size: 0.8em;
}
