.error {
  margin: 1em;
}

.sideBox {
  background-color: #fff;
  padding: 1em 0.5em;
  margin-bottom: 1em;
}

.dateTime {
  display: flex;
}

.card {
  margin-bottom: 1em;
}
