.wrapper {
  width: 800px;
  margin: 0 12px;
  padding: 0 0 12px;
}

.headerRow {
  display: flex;
  flex-direction: row;
  height: 3em;
  font-size: 0.8em;
  color: #8c8e8e;
  border-bottom: 1px solid #8c8e8e;
}

.headerRow > div {
  line-height: 3em;
  padding: 0 4px;
  box-sizing: border-box;
}

.userRow {
  display: flex;
  flex-direction: row;
  height: 3em;
  font-size: 0.9em;
  border-bottom: 1px solid #8c8e8e;
}

.userRow:hover {
  background-color: #ebeceb;
}

.userRow > div {
  line-height: 3em;
  padding: 0 4px;
  box-sizing: border-box;
}

.statusColumn {
  flex: 0 0 auto;
  flex-basis: 10%;
}

.statusColumn > div {
  margin-top: 10px;
}

.emailColumn {
  flex: 0 0 auto;
  flex-basis: 45%;
}

.nameColumn {
  flex: 0 0 auto;
  flex-basis: 35%;
}

.adminColumn {
  flex: 0 0 auto;
  flex-basis: 10%;
}

.adminColumn > div {
  margin-top: 10px;
}

.buttonColumn {
  flex: 0 0 auto;
  flex-basis: 10%;
  text-align: right;
}

.buttonColumn img {
  width: 20px;
  height: 20px;
  display: inline-block;
}

.usersContainer {
  overflow: auto;
}

@media (max-width: 1023px) {
  .wrapper {
    width: auto;
  }

  .buttonColumn {
    min-width: 88px;
  }
}
