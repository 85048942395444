.card {
  padding: 1em 1em 3em;
  margin-bottom: 1em;
}

.downloadBox {
  text-align: end;
}

.error {
  margin: 1em;
}

.tableAction {
  width: 20px;
}

.overflowWrapper {
  overflow: auto;
}
