.error {
  margin: 1em;
}

.card {
  margin-bottom: 1em;
}

.statusColumn {
  width: 20px;
}

.testSetIdColumn {
  width: 128px;
}

.testIdColumn {
  width: 128px;
}

.hpqcColumn {
  width: 100px;
}

.resultColumn {
  width: 70px;
}

.dateColumn {
  width: 10em;
}

.hpqcContainer {
  overflow: auto;
}
