.headerRow {
  display: flex;
  flex-direction: row;
  height: 4em;
  font-size: 0.8em;
  color: #8c8e8e;
  border-bottom: 1px solid #8c8e8e;
}

.headerRow > div {
  line-height: 4em;
  padding: 0 4px;
  box-sizing: border-box;
}

.itemRow {
  display: flex;
  flex-direction: row;
  height: 4em;
  font-size: 0.9em;
  border-bottom: 1px solid #8c8e8e;
}

.itemRow:hover {
  background-color: #ebeceb;
}

.itemRow > div {
  line-height: 4em;
  vertical-align: middle;
  padding: 0 4px;
}

.nameColumn {
  flex: 0 0 auto;
  flex-basis: 50%;
}

.fieldColumn {
  flex: 0 0 auto;
  flex-basis: 35%;
}

.buttonColumn {
  flex: 0 0 auto;
  flex-basis: 15%;
  text-align: right;
}

.buttonColumn > div > * {
  vertical-align: middle;
}