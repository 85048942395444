.error {
  margin: 1em;
}

.paperChartContainer {
  padding: 12px;
  position: relative;
  min-height: 300px;
}

.noApprovals {
  padding: 100px 0;
  text-align: center;
}
