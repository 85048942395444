.fields > div > * {
  display: block;
}


.title {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 0.5em;
  display: block;
}

.templateColumn {
  border-left: 1px solid rgb(224, 224, 224);
}

.template {
  clear: both;
}

.template > a {
  line-height: 45px;
}

.hamburger {
  float: right;
  display: none;
  visibility: hidden;
}

.template:hover > .hamburger {
  display: inline-block;
  visibility: visible;
}

.template * {
  -moz-transition: none !important;
  -webkit-transition: none !important;
  -o-transition: color 0 ease-in !important;
  transition: none !important;
}

.edit {
  text-align: right;
}
