@import 'core/base.module.scss';

.portalButton {
  margin-bottom: 1em !important;
}

.field {
  padding-top: 0 !important;
  width: 50% !important;
}
.tag {
  margin-left: 1em;
  width: 40% !important;
  vertical-align: top;
}

.buttonRow {
  display: flex;
  margin: 1em -1em 0;
  padding: 1.5em 1em 0;
  box-shadow: rgba(0, 0, 0, 0.12) 0px -4px 6px -6px, rgba(0, 0, 0, 0.12) 0px -4px 4px -4px;
}

.buttonRow > div {
  margin: auto 1em auto 0 !important;
}

.title {
  font-size: 24px;
  line-height: 36px;
  font-weight: inherit;
  color: $color-text;
  margin: 0 -16px;
  padding: 8px 16px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px -4px 6px -6px, rgba(0, 0, 0, 0.12) 0px -4px 4px -4px;
  border-radius: 2px;
}
