@import 'core/base.module.scss';
.card {
  min-width: 1024px;
}

.cardHeader {
  display: inline-flex;
  width: 100%;
  box-sizing: border-box;
}

.headerInfoBox {
  margin: 0 0 0 auto;
}

.exportButton {
  display: inline-block;
  vertical-align: middle;
  margin-left: 1em;
}

.wrapper {
  width: 1000px;
  margin: 0 12px;
  padding: 0 0 12px;
}

.headerRow {
  display: flex;
  flex-direction: row;
  height: 3em;
  font-size: 0.8em;
  color: $color-border-gray;
  border-bottom: 1px solid $color-border-gray;
}

.headerRow > div {
  line-height: 3em;
  padding: 0 4px;
  box-sizing: border-box;
}

.categoryRow {
  display: flex;
  flex-direction: row;
  height: 4em;
  font-size: 0.9em;
  border-bottom: 1px solid $color-border-gray;
}

.categoryRow:hover {
  background-color: $color-light-gray;
}

.categoryRow > div {
  line-height: 4em;
  padding: 0 4px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nameColumn {
  flex: 0 0 auto;
  flex-basis: 25%;
}

.scopeColumn {
  flex: 0 0 auto;
  flex-basis: 10%;
}

.regexColumn {
  flex: 0 0 auto;
  flex-basis: 35%;
}

.buttonColumn {
  flex: 0 0 auto;
  flex-basis: 30%;
  text-align: right;
}

.buttonColumn > * {
  display: inline-block;
}

/* external codes */
.codeRow {
  font-size: 0.8em;
  margin: 0 3em 1em 3em;
  width: 500px;
}

.codeRow > div {
  display: flex;
  flex-direction: row;
  height: 4em;

}

.codeRow .column {
  flex: 0 0 auto;
  flex-basis: 40%;
  vertical-align: center;
  line-height: 4em;
}

.codeRow .buttonsColumn {
  flex: 0 0 auto;
  flex-basis: 20%;
  text-align: right;
  line-height: 4em;
}

/* details */
.detailsCard {
  width: 30%;
  min-width: 400px;
}

.detailsCard > div {
  margin-bottom: 1em;
}

.codeRow > div > div {
  line-height: 2.5em;
  padding: 0 4px;
  box-sizing: border-box;
}

.detailsCard > div:last-child > button {
  margin: 0 1em 0 0;
}

/* labels */
.labelChip {
  margin: 0 0 0 0.2em;
}

@media (max-width: 1023px) {
  .detailsCard {
    min-width: 100%;
  }
}
