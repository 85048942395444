.error {
  margin: 1em;
}

.card {
  margin-bottom: 1em;
  min-width: 1000px;
}

.buttonBar {
  margin-left: 1em;
}

.buttonBar button{
  margin-right: 1em;
}

.statusColumn {
  width: 20px;
}

.errorCategoryColumn {
  width: 130px;
}

.noteColumn {
  width: 150px;
}

.testerColumn {
  width: 8em;
}

.defectsColumn {
  width: 130px;
}

.dateColumn {
  width: 10em;
}

.durationColumn {
  width: 10em;
}

.errorCategoryTooltip {
  white-space: pre;
  max-width: none;
}
