.titleHeader {
  display: inline-flex !important;
  margin-top: 0.5em;
}

.error {
  margin: 1em;
}

.iconBar {
  margin-top: -10px;
  margin-left: 12px;
}

.actionIcon {
  padding: 16px 6px 0;
  display: inline-block;
}

.testplanWarning {
  height: 24px;
  padding: 0 6px;
}

.defectIcon {
  display: inline-flex;
}

.buttons {
  padding: 1em 0 0 0.5em;
}

.moveText {
  font-size: 16px;
  height: 24px;
  padding: 4px 12px 0 8px;
}

.buttons > button:not(:first-child) {
  margin-left: 1em;
}

.card {
  height: 98%;
}

.statusColumn {
  width: 18px;
}

.dateColumn {
  width: 10em;
}

.resultColumn {
  width: 35px;
}

.issueColumn {
  width: 50px;
}

.attachments {
  display: inline-block;
  height: 20px;
  vertical-align: text-bottom;
  margin-left: 24px;
}

.attachments > div {
  display: inline-block;
  height: 20px;
}

.attachments button {
  width: 30px !important;
  height: 20px !important;
  padding: 0 !important;
}

.cdrName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px;
  display: inline-block;
  vertical-align: middle;
}

.attachFileHint {
  width: 200px;
  display: inline-block;
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.54);
}

.actions {
  vertical-align: middle;
  height: 48px;
  display: inline-flex;
  align-items: center;
}

.circularProgress {
  margin-right: 8px;
  width: 32px;
  height: 32px;
}

.verificationWarning {
  margin-top: 12px;
  color: rgb(255,153,102);
}

.overflowWrapper {
  overflow: auto;
}

@media (max-width: 1023px) {
  .testSuiteContainer > div {
    flex-basis: 100%;
    max-width: 100%;
  }
}
