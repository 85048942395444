.wrapper {
  width: 800px;
  margin: 0 12px;
  padding: 0 0 12px;
}

.headerRow {
  display: flex;
  flex-direction: row;
  height: 3em;
  font-size: 0.8em;
  color: #8c8e8e;
  border-bottom: 1px solid #8c8e8e;
}

.headerRow > div {
  line-height: 3em;
  padding: 0 4px;
  box-sizing: border-box;
}

.row {
  display: flex;
  flex-direction: row;
  height: 5em;
  font-size: 0.9em;
  border-bottom: 1px solid #8c8e8e;
}

.row:hover {
  background-color: #ebeceb;
}

.row > div {
  line-height: 5em;
  padding: 0 4px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.statusColumn {
  flex: 0 0 auto;
  flex-basis: 10%;
}

.statusColumn > div {
  margin-top: 10px;
}

.codeColumn {
  flex: 0 0 auto;
  flex-basis: 20%;
}

.nameColumn {
  flex: 0 0 auto;
  flex-basis: 30%;
}

.dateColumn {
  flex: 0 0 auto;
  flex-basis: 25%;
}

.buttonColumn {
  flex: 0 0 auto;
  flex-basis: 15%;
  text-align: right;
}

.buttonColumn img {
  width: 20px;
  height: 20px;
  margin: 0 0.5em 0 0;
  display: inline-block;
}

.projectsContainer {
  overflow: auto;
}
