@import 'core/base.module.scss';

// date filter
.dateTimeContainer {
  width: 100%;
}

.dateTime {
  display: flex;
}

.dateTime:not(:last-of-type) {
  margin-bottom: 1em;
}

// item count
.passedCount {
  color: $color-green;
}

.failedCount {
  color: $color-red;
}

// filter chips
.chipBox {
  padding: 0.5em;
  margin-bottom: 1em;
  position: sticky;
  top: 0.5em;
  z-index: 100;
  text-align: center;
}

.chipBox > div {
  margin-right: 0.5em;
  display: inline-flex !important;
}

.datePicker {
  margin-right: 1em !important;
}
