.button {
  margin: 0.5em 0.5em 0.5em 0 !important;
}

.logoutContent {
  padding: 3em 1em 1em;
  margin-top: 128px;
}

@media (min-width: 1199px) {
  .productsHeroIcon {
    height: 500px;
    padding: 0 15px;
  }
}

@media (max-width: 1199px) and (min-width: 850px) {
  .productsHeroIcon {
    height: 250px;
  }
}

@media (max-width: 850px) {
  .productsHeroIcon {
    display: none;
  }
}

.logoImage {
  height: 110px;
  padding-bottom: 30px;
}

.slogan {
  font-size: 26px;
  color: #4a4a4a;
  font-weight: bold;
}
