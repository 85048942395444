.card {
  margin-bottom: 0.5em;
}

.buttonRow {
  text-align: right;
}

.buttonRow > button {
  margin: 1em;
}

.patternList {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.patternList > * {
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.patternList > div:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.patternList > div > form {
  flex-grow: 1;
  padding-right: 1em;
}

.newPatternRow {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.newPatternRow:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}
