@font-face {
  font-family: AvenirLT;
  src:    url('https://www.qitasc.com/assets/webfonts/Avenir.ttf');
  font-style: normal;
  font-display: fallback;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: AvenirLT, Roboto, sans-serif;
}

a {
  text-decoration: none;
}

a:link,
a:visited {
  color: #231f20;
}

a:visited:hover,
a:link:hover {
  color: #f7941e;
}

/* stylelint-disable-next-line selector-pseudo-class-no-unknown */
:global span.link:hover {
  color: #f7941e;
  cursor: pointer;
}

.chartContainer > div > div {
  margin: 8px 4px;
}

@media (max-width: 1023px) {
  .dashboardCol {
    width: 100%;
  }
}
