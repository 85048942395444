
/* logLines */
.logPanel {
  margin-top: 1em;
}

.extensionPanelTitle {
  padding: 1em 1.5em !important;
  cursor: pointer;
}

.extensionPanelTitle span {
  font-size: 1rem;
}

.deletedMessage {
  display: flex;
}

.deletedMessage > img {
  margin: 0 2em;
}

.deletedMessage > span {
  line-height: 30px;
}

.text {
  font-size: 1em;
  line-height: 1.4em;
  padding-left: 1em;
  font-family: monospace;
  white-space: pre;
  overflow-x: auto;
}

.text > p {
  margin: 0;
}

.deletedIcon {
  width: 30px;
  height: 30px;
}

/* dataField */
.dataField {
  padding: 0.5em;
  overflow: hidden;
}

.dataField > :first-child {
  font-size: 12px;
  color: #8c8c8e;
}

.dataField :last-child {
  font-size: 16px;
  min-height: 19px;
}

/* defects */
.defectForm {
  display: inline;
}

.defectField {
  width: 120px !important;
}

.iconButton {
  padding: 6px !important;
  width: 34px !important;
}

.tag {
  background-color: rgb(224, 224, 224);
  border-radius: 16px;
  border: 10px;
  padding: 5px 10px 0;
  margin: 0 1px 0 0;
  display: inline-flex;
}

/* pin */
.icon {
  width: 20px;
  height: 20px;
}

.buttonRole:focus {
  outline: 0;
  opacity: 0.7;
}

/* error message */
.errorMessage {
  font-size: 1em;
  line-height: 1.4em;
  font-family: monospace;
  white-space: pre-wrap;
}

.errorDialog {
  white-space: pre !important;
}

/* testCategorySelector */
.testCategorySelector {
  width: 100%;
}