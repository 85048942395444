.card {
  height: 98%;
}

.tableContainer {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.icon {
  vertical-align: bottom !important;
  margin-left: 10px;
}

.paddedBox {
  padding-top: 8px;
}

.iconTooltip {
  white-space: pre;
  max-width: none;
  text-align: left;
}

.overflowWrapper {
  overflow: auto;
}
