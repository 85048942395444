.error {
  margin: 1em;
}

.dateTime {
  display: flex;
}

.card {
  margin-bottom: 1em;
}

.cardHeader {
  display: inline-flex;
  width: 100%;
}

.container {
  min-width: 1400px;
  flex-wrap: nowrap;
}

.statusColumn {
  width: 1em;
}

.testerColumn {
  width: 15em;
}

.dateColumn {
  width: 10em;
}

.durationColumn {
  width: 10em;
}

.moveToProjectDiv {
  display: inherit !important;
}

.moveToProjectDiv > span {
  display: inline-block;
  margin: 0 1em;
  width: 15em;
  position: relative;
}

.errorCategoryTooltip {
  white-space: pre;
  max-width: none;
}

.addToProjectButtonIcon {
  margin-left: 0.5em;
}

.countLabel {
  font-size: small;
  align-self: center;
  text-align: end;
  color: grey;
  width: 70%;
}

.unassignedContainer {
  overflow: auto;
}
