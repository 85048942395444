@import "core/base.module.scss";

.appbar {
  margin-bottom: 4px;
}

.toolbar {
  min-height: 128px !important;
  padding-top: 0.5em;
  padding-bottom: 0;
}

.logo {
  width: 60px;
  height: 60px;
  display: inline-block;
  vertical-align: text-bottom;
}

.tabBar {
  align-self: flex-end;
  min-width: 200px;
}

.title {
  flex-grow: 1;
  font-weight: 200;
  font-size: 48px;
  line-height: 60px;
  white-space: nowrap;
}

.title sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
  top: -0.4em;
}

.topbar {
  position: absolute;
  top: 0;
  right: 20px;
  display: flex;
}

.topbar > * {
  align-self: flex-start;
  height: 48px;
  margin-left: 0.5em !important;
}

.notifications {
  width: 480px;
  max-height: 642px;
}

.notifications .header {
  height: 52px;
  line-height: 20px;
  font-size: 20px;
  color: $color-text;
  padding: 16px;
  border-bottom: 1px solid $color-border-gray;
  background-color: $color-light-gray;
}

.notifications .container .item {
  margin: 16px;
  display: flex;
}

.notifications .container .item .icon {
  padding: 8px;
  width: 96px;
  text-align: center;
}

.notifications .container .item .content {
  padding: 16px 16px 16px;
  width: 100%;
}

.notifications .largeIcon {
  display: flex;
  flex-direction: column;
}

.notifications .largeIcon span {
  width: 100px;
  margin: 0 auto;
  color: $color-dark-gray !important;
  font-size: 100px !important;
}

.notifications .largeIcon a {
  padding: 16px 48px;
  color: rgba($color-text, 0.5);
  text-align: center;
}

.mobileMenuContainer {
  display: none;
}

.mobileUserName {
  display: none;
}

.notificationItem {
  display: flex;
  margin: 16px;
}

.sendCodeContainer {
  display: flex;
  margin-top: 2px;
  align-items: baseline;
}

.sendCodeBtn {
  margin-left: 24px !important;
}

.pendingNotification {
  align-items: center;
}

@media (max-width: 1023px) {
  .tabBar,
  .title {
    display: none;
  }

  .toolbar {
    min-height: 64px !important;
    padding: 8px;
  }

  .userName {
    display: none;
  }

  .mobileMenuContainer {
    display: block;
  }

  .topbar {
    position: initial;
    margin-left: auto;
  }

  .mobileDrawerContent {
    width: 90vw;
    background-color: #fafafa;
    height: 100%;
  }

  .appBarBtn {
    min-width: 24px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 12px !important;
  }

  .appBarBtn > * {
    margin: 0 !important;
  }

  .menuBtn a {
    align-items: center;
    display: flex;
    width: 100%;
  }

  .menuItemIcon {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }

  .mobileUserName {
    display: flex;
  }
}
