.card {
  margin-bottom: 0.5em;
}

.wrapper {
  width: 100%;
  margin: 0 12px;
}

.settingsContent {
  width: 100%;
  max-width: 400px;
}

.settingsContent > label {
  display: block;
  margin-left: -7px;
}

.settingsContent > * {
  margin-bottom: 0.5em;
}

.headerRow {
  display: flex;
  flex-direction: row;
  height: 4em;
  font-size: 1em;
  color: #8c8e8e;
}

.headerRow > div {
  line-height: 4em;
  padding: 0 4px;
  box-sizing: border-box;
  overflow: hidden;
}

.itemRow {
  display: flex;
  flex-direction: row;
  height: 4em;
  font-size: 0.9em;
  border-top: 1px solid #8c8e8e;
}

.itemRow:hover {
  background-color: #ebeceb;
}

.itemRow > div {
  line-height: 4em;
  padding: 0 4px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nameColumn {
  flex: 0 0 auto;
  flex-basis: 19%;
}

.codeColumn {
  flex: 0 0 auto;
  flex-basis: 19%;
}

.tagColumn {
  flex: 0 0 auto;
  flex-basis: 19%;
}

.defaultColumn {
  flex: 0 0 auto;
  flex-basis: 19%;
}

.typeColumn {
  flex: 0 0 auto;
  flex-basis: 14%;
}

.buttonColumn {
  display: flex;
  flex: 0 0 auto;
  flex-basis: 10%;
}

.errorRow {
  color: red;
}
