.wrapper {
  min-width: 1400px;
}

.error {
  margin: 1em;
}

.sideBox {
  background-color: #fff;
  padding: 1em 0.5em;
  margin-bottom: 1em;
}

.card {
  margin-bottom: 1em;
}

.selectionMessage {
  color: #8c8c8e;
  font-size: 0.9em;
  text-align: center;
}

.selectionMessage u {
  cursor: pointer;
}

.buttonPanel {
  margin-bottom: 1em;
}

.buttonPanel > button {
  margin-left: 1em;
}

.import {
  margin: 0 0 0 auto;
}

.cardActions {
  display: flex;
  justify-content: flex-end;
}
