.card {
  margin-bottom: 1em;
}

.buttonPanel > button {
  margin-left: 1em;
}

.errorCategoryColumn {
  width: 15em;
}

.errorCategoryTooltip {
  white-space: pre;
  max-width: none;
}

.warning {
  width: 20px;
  height: 20px;
  margin: 0 4px -4px 4px;
}

.approvalContainer {
  overflow: auto;
}
