.wrapper {
  max-width: 1400px;
  min-width: 1000px;
  padding: 0 0 12px;
}

.headerRow {
  display: flex;
  flex-direction: row;
  height: 3em;
  font-size: 0.8em;
  color: #8c8e8e;
  border-bottom: 1px solid #8c8e8e;
}

.headerRow > div {
  line-height: 3em;
  padding: 0 4px;
  box-sizing: border-box;
}

.configRow {
  display: flex;
  flex-direction: row;
  height: 4em;
  font-size: 0.9em;
  border-bottom: 1px solid #8c8e8e;
}

.configRow:hover {
  background-color: #ebeceb;
}

.configRow > div {
  line-height: 4em;
  padding: 0 4px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nameColumn {
  flex: 0 0 auto;
  flex-basis: 20%;
}

.categoryColumn {
  flex: 0 0 auto;
  flex-basis: 16%;
}

.dateColumn {
  flex: 0 0 auto;
  flex-basis: 20%;
}

.testSetIdColumn {
  flex: 0 0 auto;
  flex-basis: 10%;
}

.enabledColumn {
  flex: 0 0 auto;
  flex-basis: 7%;
}

.buttonColumn {
  flex: 0 0 auto;
  flex-basis: 7%;
  text-align: right;
}

.buttonColumn > div {
  margin-top: 5px !important;
}

.validationError {
  color: #c62828;
  font-size: 0.8em;
}
