.resizer {
  display: inline-block;
  background: #e0e0e0;
  width: 2px;
  height: 28px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  transform: translateY(50%);
  z-index: 1;
  touch-action: none;
  user-select: none;
}

.resizer.hidden {
  display: none;
}

.column-header {
  display: flex;
  align-items: center;
}

.removeFieldButton {
  opacity: 0;
  margin-left: 3px;
  font-size: 16px !important;
  font-weight: 900;
  cursor: pointer;
  transition: .3s opacity;
}

.column-header:hover .removeFieldButton {
  opacity: 1;
}
