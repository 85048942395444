.form {
  min-width: 400px;
  max-width: 25%;
}

.form > label {
  display: block;
}

.buttonRow > button {
  margin: 1em 0 0;
}

@media (max-width: 1023px) {
  .form {
    min-width: 100%;
  }
}
