.card {
  height: 98%;
}

.buttons {
  padding: 8px 8px 0;
}

.overflowWrapper {
  overflow: auto;
}
