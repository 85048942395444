.error {
  margin: 1em;
}

.line {
  display: flex;
}

.filterBlock {
  display: flex;
  margin-left: 11em;
}

.cell {
  height: 40px;
  width: 40px;
  border-color: #ebeceb;
  border-style: solid;
  border-width: 0 1px 1px 0;
}

.passed {
  background-color: #3c8863;
}

.failed {
  background-color: #f44;
}

.firstline {
  display: flex;
  padding-left: 172px;
}

.testSuite {
  width: 160px;
  flex: 0 0 160px;
  border-color: #bfc2c9;
  border-style: solid;
  border-width: 0 1px 0 0;
  padding: 12px 12px 0 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  font-size: 12px;
}

.dates {
  width: 41px;
  font-size: 11px;
  text-align: center;
  padding-top: 20px;
  overflow: hidden;
  border-color: #bfc2c9;
  border-style: solid;
  border-width: 0 0 1px;
}

.filterDateInput {
  margin: 0 16px !important;
}

.filterTextField {
  width: 250px;
}

.emptyListMsg {
  color: #5f9ea0;
  padding-left: 172px;
}
