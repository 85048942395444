.wrapper {
  width: 600px;
  margin: 0 12px;
  padding: 0 0 12px;
}

.headerRow {
  display: flex;
  flex-direction: row;
  height: 3em;
  font-size: 0.8em;
  color: #8c8e8e;
  border-bottom: 1px solid rgb(0,0,0,0.1);
}

.headerRow > div {
  line-height: 3em;
  padding: 0 4px;
  box-sizing: border-box;
}

.categoryRow {
  display: flex;
  flex-direction: row;
  height: 4em;
  font-size: 0.9em;
  border-bottom: 1px solid rgb(0,0,0,0.1);
}

.categoryRow:hover {
  background-color: #ebeceb;
}

.categoryRow > div {
  line-height: 3em;
  padding: 0 4px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nameColumn {
  flex: 0 0 auto;
  flex-basis: 50%;
}

.tagColumn {
  flex: 0 0 auto;
  flex-basis: 35%;
}

.buttonColumn {
  flex: 0 0 auto;
  flex-basis: 15%;
  text-align: right;
}

@media (max-width: 1023px) {
  .wrapper {
    width: auto;
  }

  .nameColumn {
    flex-basis: 45%;
  }

  .categoryRow .buttonColumn {
    flex-basis: 20%;
    overflow: visible;
  }
}
