.confirmTooltip {
  font-size: 14px;
  padding: 8px 0;
  text-align: center;
}

.confirmTooltipButtons {
  margin-top: 8px;
}

.confirmTooltipButtons button:last-of-type {
  margin-left: 8px;
}
