.container {
  min-width: 1400px;
  flex-wrap: nowrap;
  display: flex;
}

.content {
  flex-grow: 1;
}

.error {
  margin: 1em;
}

.dateTime {
  display: flex;
}

.errorCategoryColumn {
  width: 130px;
}

.errorCategoryTooltip {
  white-space: pre;
  max-width: none;
}

// filter drawer
.hidden {
  display: none;
}

.drawer {
  z-index: 100;
}

.drawer > * {
  width: 500px;
  margin-left: 0.5em;
}
