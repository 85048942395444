$color-orange: #f7941e;
$color-red: #c62828;
$color-green: #2e7d32;
$color-light-gray: #ebeceb;
$color-dark-gray: #e3e3e3;
$color-border-gray: #8c8e8e;
$color-text: #32323c;

.orange {
  color: $color-orange;
}
