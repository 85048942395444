.validationError {
  color: red;
}

.wrapper {
  width: 800px;
  margin: 0 12px;
  padding: 0 0 12px;
}

.dialogContent > * {
  display: block !important;
}

.trackContainer {
  overflow: auto;
}
