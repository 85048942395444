.paper {
  padding: 16px;
}

.paper > h4 {
  font-size: 1.5em;
  line-height: 32px;
  margin: 32px 0 0;
}

.error {
  margin: 1em;
}

.logCard {
  margin-top: 10px;
}
