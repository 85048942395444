.card {
  margin-top: 1em;
}

.wrapper {
  width: 800px;
  margin: 0 12px;
  padding: 0 0 12px;
}

.headerRow {
  display: flex;
  flex-direction: row;
  height: 3em;
  font-size: 0.8em;
  color: #8c8e8e;
  border-bottom: 1px solid #8c8e8e;
}

.headerRow > div {
  line-height: 3em;
  padding: 0 4px;
  box-sizing: border-box;
}

.roleRow {
  display: flex;
  flex-direction: row;
  height: 3em;
  font-size: 0.9em;
  border-bottom: 1px solid #8c8e8e;
}

.roleRow:hover {
  background-color: #ebeceb;
}

.roleRow > div {
  line-height: 3em;
  padding: 0 4px;
  box-sizing: border-box;
}

.projectColumn {
  flex: 0 0 auto;
  flex-basis: 50%;
}

.roleColumn {
  flex: 0 0 auto;
  flex-basis: 40%;
}

.buttonColumn {
  flex: 0 0 auto;
  flex-basis: 10%;
  text-align: right;
}

.buttonColumn img {
  width: 20px;
  height: 20px;
  display: inline-block;
}

.apiSecretForm {
  padding: 12px;
}
