// header
.tableHeader {
  padding-right: 0 !important;
  padding-left: 1em !important;
}

.title {
  flex: 0 0 auto;
  margin-left: 0.5em;
}
.spacer {
  flex: 1 1 auto;
}
.actions > * {
  display: inline-block;
}

.countLabel {
  font-size: small;
  color: #8c8c8e;
  display: inline-block;
  line-height: 34px;
}
