.content {
  width: 30%;
  min-width: 400px;
}

.content > * {
  margin-bottom: 0.5em;
}

.content > label {
  margin-left: -7px;
}

.buttonRow {
  display: flex;
  margin: 1em -1em 0;
  padding: 1.5em 1em 0;
  box-shadow: rgba(0, 0, 0, 0.12) 0px -4px 6px -6px, rgba(0, 0, 0, 0.12) 0px -4px 4px -4px;
}

.buttonRow > div {
  margin: auto 1em auto 0 !important;
}
