.paper {
  padding: 16px;
}

.paper > h4 {
  font-size: 1.5em;
  line-height: 32px;
  margin: 32px 0 0;
}

.sideBox {
  background-color: #fff;
  padding: 1em 0.5em;
  margin-bottom: 1em;
}

.dateTime {
  display: flex;
}

.cardActions {
  display: flex;
  justify-content: flex-end;
}

.card {
  margin-bottom: 1em;
}

.statusColumn {
  width: 20px;
}

.iconBar {
  margin-top: -10px;
  margin-left: 12px;
}

.actionIcon {
  padding: 16px 6px 0;
}

.ticketWarning {
  padding: 0 6px;
}

.titleContainer {
  display: inline-flex;
}

.ticketIdForm {
  display: inline;
}

.ticketIdField {
  width: 120px !important;
}

.error {
  margin: 1em;
}

.whiteSpace {
  white-space: pre;
}

.openYoutrackButton {
  color: #231f20 !important;
  font-size: 20px !important;
  position: absolute;
  margin-top: 15px;
}

.hpqcButtons > button {
  margin: 0 6px 6px 0;
}
