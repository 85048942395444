.error {
  margin: 1em;
}

.paperChartContainer {
  padding: 12px;
  position: relative;
  min-height: 300px;
}

.title {
  font-size: 20px;
}

.subtitle {
  font-size: 16px;
}

.legend {
  text-align: center;
}

.noExecutionsLastWeek {
  padding: 100px 0;
  text-align: center;
}
