.list {
  margin: 1em 0;
  width: 30%;
  min-width: 400px;
}

.contents > h3 {
  margin-top: 2em;
}

.buttonRow > div {
  margin: 0 0 0 1em;
}

@media (max-width: 1023px) {
  .list {
    min-width: 100%;
  }
}
