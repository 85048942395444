.card {
  margin-bottom: 0.5em;
}

.content {
  width: 30%;
  min-width: 400px;
}

.error {
  margin: 1em;
}

@media (max-width: 1023px) {
  .content {
    min-width: 100%;
  }
}
