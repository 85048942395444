.card {
  margin-bottom: 1em;
  min-height: 300px;
}

.buttonPanel > button {
  margin-left: 1em;
}

.statusColumn {
  width: 20px;
}

.errorCategoryColumn {
  width: 15em;
}

.errorCategoryTooltip {
  white-space: pre;
  max-width: none;
}

.dateColumn {
  width: 10em;
}

.durationColumn {
  width: 10em;
}

.verificationContainer {
  overflow: auto;
}
