.contents {
  max-width: 600px;
}

.code {
  font-family: monospace;
  font-size: 14px;
  white-space: pre-wrap;
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: 1em;
  padding: 5px;
  background-color: #eff0f1;
  width: auto;
}

.textMuted {
  margin-top: 0;
  font-size: 80%;
  font-weight: 400;
}
