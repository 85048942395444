/* title */
.statusIcon {
  vertical-align: top;
}

.headline {
  margin: 0;
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stepDetails {
  margin: 0;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}

.buttonsEditingContainer,
.editBtnContainer {
  display: flex;
  align-items: center;
  margin-left: 6px;
}
