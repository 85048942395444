.error {
  margin: 1em;
}

.card {
  margin-bottom: 1em;
  min-width: 1000px;
}

.errorCategoryColumn {
  width: 10em;
}

.errorCategoryTooltip {
  white-space: pre;
  max-width: none;
}
