.previewBox > img {
  margin: 0 auto;
  display: block;
}

.previewBox > p {
  font-size: 13px;
  line-height: 20px;
  margin-top: 16px;
  padding-left: 16px;
  white-space: pre-wrap;
  font-family: monospace;
}

.attachmentDialog {
  padding-top: 0 !important;
}

.attachmentDialog > div > div {
  width: 80% !important;
  max-width: inherit !important;
}

.attachmentDialogContent > div > div:first-of-type {
  padding: 24px !important;
}

.iframe {
  width: 100%;
  height: 65vh;
  border: 0;
}
