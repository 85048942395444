.card {
  margin-top: 1em;
}

.error {
  margin: 1em;
}

.tableRow {
  padding: 14px 24px;
}

.tableRow:hover {
  cursor: pointer;
}

.headerData {
  padding: 10px;
}

.headerDataName {
  font-size: 12px;
  color: #8c8c8e;
}

.content {
  white-space: pre;
  font-family: monospace;
  overflow-x: scroll;
  tab-size: 4;
  padding: 0 1em 1em;
}
