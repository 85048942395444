@import 'core/base.module.scss';

.paper {
  padding: 16px;
}

.paper > h4 {
  font-size: 1.5em;
  line-height: 32px;
  margin: 32px 0 0;
}

.error {
  margin: 1em;
}

.card {
  margin-top: 10px;
}

.buttons {
  margin-top: 1em;
}

.attachments {
  display: inline-block;
  height: 20px;
  vertical-align: text-bottom;
  margin-left: 24px;
}

.attachments > div {
  display: inline-block;
  height: 20px;
}

.attachments button {
  width: 30px !important;
  height: 20px !important;
  padding: 0 !important;
}



