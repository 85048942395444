@import 'core/base.module.scss';

$color-tag: #808000;

.attachments {
  display: inline-block;
  height: 20px;
  vertical-align: text-bottom;
}

.attachments > div {
  display: inline-block;
  height: 20px;
}

.attachments button {
  width: 30px !important;
  height: 20px !important;
  padding: 0 !important;
}

.result > span {
  top: 2px;
}

.params {
  margin: 1em 0;
  overflow: hidden;
}

.params > div {
  font-size: 11px;
  white-space: nowrap;
  display: flex;
}

.params > div > span {
  width: 200px;
  display: inline-block;
  text-align: right;
}

.params > div > span::after {
  content: ':';
  margin: 0 4px;
}

.fileContainer {
  font-family: monospace;
  font-size: 14px;
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
}

.fileContainer > div {
  height: 21px;
  line-height: 21px;
  padding-left: 55px;
  position: relative;
  transition: 0.2s transform ease-out;
}

.params > div > div {
  white-space: pre;
  font-family: monospace;
}

.expanded > div > div:last-child {
  transform: translateX(73px);
  transition: 0.2s transform ease-in-out;
}

.selected > * {
  background-color: hsla(197, 60%, 80%, 1) !important;
}

.fileContainer > div:hover > * {
  background-color: #c6dbe3 !important;
}

.fileContainer > div > div:first-child {
  background-color: $color-light-gray;
  position: absolute !important;
  padding-right: 4px;
  left: 0;
  width: 133px;
  top: 0;
  height: 21px;
  line-height: 21px;
}

.fileContainer > div > div:first-child > span:first-child {
  text-align: right;
  display: inline-block;
  width: 25px;
  margin-right: 8px;
}

.fileContainer > div > div:first-child > span:last-of-type {
  display: inline-block;
  width: 70px;
  margin-left: 3px;
  opacity: 0;
}

.expanded > div > div:first-child > span:last-of-type {
  opacity: 0.75;
  transition: opacity 0.3s ease-in;
}

.result {
  display: inline-block;
  margin-top: 0;
}

.fileContainer > div > div:last-child {
  height: 21px;
  line-height: 21px;
  white-space: nowrap;
  position: relative;
  background-color: white;
  border-left: 1px black solid;
  transition: 0.2s transform ease-in-out;
}

.events {
  display: inline-block;
  height: 20px;
  vertical-align: text-bottom;
  color: #8c8c8e !important;
  cursor: pointer;
}

.events i {
  width: 30px;
  text-align: center;
}

.comment {
  font-style: italic;
  color: #808080;
  white-space: pre;
}

.comment > div {
  display: inline;
}

.keyword {
  @extend .orange;
  margin-right: 6px;
}

.keywordWithColon {
  @extend .keyword;
}

.keywordWithColon::after {
  content: ":";
}

.separator {
  @extend .orange;
}

.detail {
  padding-left: 68px;
}

.step {
  padding-left: 48px;
}

.test {
  padding-left: 28px;
}

.suite {
  padding-left: 8px;
}

.testErrorCategory {
  border: 1px solid $color-red;
  padding: 0 4px;
  margin-left: 1em;
  font-style: italic;
  font-size: small;
}

.tag {
  color: $color-tag;
  margin-right: 8px;
}

.example {
  white-space: pre-wrap;
  cursor: pointer;
}

:not(.selectedExample) > .exampleParameter {
  opacity: 0.4;
}

.transitionOpacity {
  -webkit-transition: opacity .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out;
  -ms-transition: opacity .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out;
  transition: opacity .2s ease-in-out;
}

.example > img {
  @extend .transitionOpacity;
  width: 15px;
  height: 15px;
  position: absolute;
  left: 23px;
  top: 3px;
  opacity: 0.4;
}

.example:hover > img {
  opacity: 1;
}

.metadata {
  cursor: pointer;
}

.metadata:focus {
  outline: none;
}

.metadataIcon {
  margin-left: 0.5em;
  line-height: 21px;
  vertical-align: bottom;
}

.metadataName {
  color: $color-tag;
  font-style: italic;
  white-space: pre;
}

.metadataPreview {
  font-size: 13px;
  line-height: 20px;
  margin-top: 16px;
  padding-left: 16px;
  white-space: pre-wrap;
  font-family: monospace;
}

.separator {
    height: 24px !important;
}

.separator > div {
    height: 24px !important;
}

.separator:hover > div:first-child {
    background-color: $color-light-gray !important;
}

.separator:hover > div:last-child {
    background-color: #FFF !important;
}

.separator > div:last-child {
    transition: opacity 150ms ease-out;
    background-color: $color-dark-gray;
}

.separatorCut::before, .separatorCut::after {
    display: block;
    background-repeat: repeat-x !important;
    background-size: 12px 12px,12px 12px !important;
    width: 100%;
    height: 12px;
    opacity: .5;
    content: "";
}

.separatorCut::before {
    background: linear-gradient(-45deg, transparent 50%, #FFF 50%) 0 50%,linear-gradient(45deg, transparent 50%, #FFF 50%) 0 50%,linear-gradient(45deg, $color-dark-gray, $color-dark-gray);
}

.separatorCut::after {
    background: linear-gradient(-45deg, transparent 50%, $color-dark-gray 50%) 0 50%,linear-gradient(45deg, transparent 50%, $color-dark-gray 50%) 0 50%,linear-gradient(45deg, #FFF, #FFF);
}

.separatorCut:hover::before {
    opacity: .75;
}

.separatorCut:hover::after {
    opacity: .75;
}
